import { METHOD, request } from "@/utils/request";
import { LOCK_CARD } from "./api";

export async function getListLockCards({ lockId, cardNumber }) {
  return request(LOCK_CARD, METHOD.GET, { lockId, cardNumber });
}

export async function saveLockCard(params) {
  return request(LOCK_CARD, METHOD.POST, params);
}

export async function updateLockCard(params) {
  return request(`${LOCK_CARD}/${params.cardNumber}`, METHOD.PUT, params);
}

export async function deleteLockCard({ lockId, cardNumber }) {
  return request(LOCK_CARD, METHOD.DELETE, { lockId, cardNumber });
}

export default {
  getListLockCards,
  saveLockCard,
  updateLockCard,
  deleteLockCard,
};
