import { createRouter, createWebHistory } from "vue-router";
import { checkAuthorization } from "@/utils/routerUtil";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/AuthenticatorView/Login"),
  },
  {
    path: "/lock",
    name: "lock",
    component: () => import("../views/ManageLockView"),
  },
  {
    path: "/card",
    name: "card",
    component: () => import("../views/ManageCardView"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve((to, from, next) => {
  // Check authorization or any other logic using 'from'
  if (to.path === "/" && !checkAuthorization()) {
    // Redirect to login only if the user is not authorized
    next({ name: "login" });
  } else if (to.path === "/" && checkAuthorization()) {
    next({ name: "lock" });
  } else {
    // Proceed with the navigation
    next();
  }
});

export default router;
