import { METHOD, request } from "@/utils/request";
import { CARD } from "./api";

export async function getListCards() {
  return request(CARD, METHOD.GET);
}

export async function saveCard(params) {
  return request(CARD, METHOD.POST, params);
}

export async function updateCard(params) {
  return request(`${CARD}/${params.cardNumber}`, METHOD.PUT, params);
}

export async function deleteCard(cardNumber) {
  return request(`${CARD}/${cardNumber}`, METHOD.DELETE);
}

export default {
  getListCards,
  saveCard,
  updateCard,
  deleteCard,
};
