<template>
  <a-layout>
    <a-layout-sider
      v-model:collapsed="collapsed"
      :trigger="null"
      collapsible
      class="layout-custom"
    >
      <p class="logo">Javis Smart Lock</p>
      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
        <a-menu-item key="1">
          <LockOutlined />
          <span><router-link to="/lock">Danh sách khóa</router-link></span>
        </a-menu-item>
        <a-menu-item key="2">
          <CreditCardOutlined />
          <span><router-link to="/card">Quản lý thẻ từ</router-link></span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined
          v-else
          class="trigger"
          @click="() => (collapsed = !collapsed)"
        />
      </a-layout-header>
      <a-layout-content
        :style="{
          margin: '24px 16px',
          padding: '24px',
          background: '#fff',
          minHeight: '280px',
        }"
      >
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { ref } from "vue";
import {
  LockOutlined,
  CreditCardOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons-vue";
export default {
  components: {
    LockOutlined,
    CreditCardOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  },
  data() {
    return {
      collapsed: false,
      selectedKeys: ref(["1"]),
    };
  },
  mounted() {
    const url = window.location.href;
    if (url.includes("lock")) {
      this.selectedKeys = ref(["1"]);
    } else if (url.includes("card")) {
      this.selectedKeys = ref(["2"]);
    }
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.layout-custom {
  height: 100vh;
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  padding: 0px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.site-layout .site-layout-background {
  background: #fff;
}
</style>
