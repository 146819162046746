const routeList = [
  {
    path: "/lock",
  },
  {
    path: "/card",
  },
];

function getFirstRoutePath() {
  return routeList[0].path;
}

function checkAuthorization() {
  if (localStorage.getItem("isLogined")) {
    return false;
  }
  return false;
}

export { getFirstRoutePath, checkAuthorization };
