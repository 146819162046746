import apiService from "@/services/lockCard";

export default {
  namespaced: true,
  state: {
    listLockCards: [],
  },
  getters: {},
  actions: {
    async getListLockCards(context, params) {
      const { data } = await apiService.getListLockCards(params);
      context.commit("setLockCardList", data);
    },
    async saveLockCard(context, body) {
      await apiService.saveLockCard(body);
    },
    async deleteLockCard(context, params) {
      await apiService.deleteLockCard(params);
    },
    resetListLockCards(context) {
      context.commit("setLockCardList", []);
    },
  },
  mutations: {
    setLockCardList(state, list) {
      state.listLockCards = list;
    },
  },
};
