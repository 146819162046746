import apiService from "@/services/card";

export default {
  namespaced: true,
  state: {
    listCards: [],
    card: {},
  },
  getters: {},
  actions: {
    async getListCards(context) {
      const { data } = await apiService.getListCards();
      context.commit("setCardList", data);
    },
    async saveCard(context, body) {
      await apiService.saveCard(body);
    },
    async deleteCard(context, cardNumber) {
      await apiService.deleteCard(cardNumber);
    },
    async updateCard(context, body) {
      await apiService.updateCard(body);
    },
    selectCard(context, card) {
      context.commit("setCard", { ...card });
    },
  },
  mutations: {
    setCardList(state, list) {
      state.listCards = list;
    },
    setCard(state, card) {
      state.card = card;
    },
  },
};
