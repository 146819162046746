import { METHOD, request } from "@/utils/request";
import { LOCK } from "./api";

export async function getListLocks() {
  return request(LOCK, METHOD.GET);
}

export default {
  getListLocks,
};
