import apiService from "@/services/lock";

export default {
  namespaced: true,
  state: {
    listLocks: [],
    lock: {},
  },
  getters: {},
  actions: {
    async getListLocks(context) {
      const { data } = await apiService.getListLocks();
      context.commit("setLockList", data);
    },
    selectLock(context, lock) {
      context.commit("setLock", { ...lock });
    },
  },
  mutations: {
    setLockList(state, list) {
      state.listLocks = list;
    },
    setLock(state, lock) {
      state.lock = lock;
    },
  },
};
